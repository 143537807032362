import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import FaqPageSection from "../components/FaqPageSection/FaqPageSection"
import SeoPage from "../components/seo-page"
import { useTranslation } from "react-i18next"

const FaqsPage = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const seoFields = {
    keyword: {
      en: "faq",
      ro: "faq",
    },
    slug: {
      en: "FAQs",
      ro: "FAQs",
    },
  }

  function getSeoFields(field) {
    return seoFields[field][language] ?? seoFields[field].ro // return ro fields if current language does not have them
  }
  return (
    <Layout>
      <Header />
      <SeoPage keyword={getSeoFields("keyword")} slug={getSeoFields("slug")} />
      <FaqPageSection />
      <Footer />
    </Layout>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default FaqsPage
