import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useFAQs } from "../../hooks/FAQs/useFAQs"
import FaqCollapsible from "../FaqCollapsible/FaqCollapsible"
import "../FaqSection/FaqSection"

const FaqPageSection = () => {
  const [update, setUpdate] = useState(false)

  const { t } = useTranslation()

  const faqs = useFAQs()

  const queryString = typeof window !== "undefined" ? window.location.hash : ""

  const getQuestionId = string => {
    return string
      .replace(/([^A-Za-z0-9[\]{} _.:-])?/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase()
  }

  return (
    <section className="faq-section">
      <div className="container">
        <h1 className="text-center" style={{ fontWeight: "400" }}>
          <strong>
            {faqs.length ? t("Întrebări frecvente") : t("Nu sunt întrebări")}
          </strong>
        </h1>
        <div className="row" style={{ marginBottom: "15rem" }}>
          <div className="col-md-2" />
          <div className="col-md-8">
            <div className="faq-list">
              <table className="table">
                <tbody>
                  {(faqs &&
                    faqs.length &&
                    faqs.map(q => (
                      <FaqCollapsible
                        key={`qq-${q.id}`}
                        id={getQuestionId(q.id)}
                        queryString={queryString.replace("#", "")}
                        setUpdate={setUpdate}
                        update={update}
                        title={q.faqFields?.question}
                        copy={q.faqFields?.answer}
                      />
                    ))) ||
                    ""}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-2" />
        </div>
      </div>
    </section>
  )
}

export default FaqPageSection
