import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"

export const useFAQs = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { allWpFaqRo, allWpFaqEn } = useStaticQuery(graphql`
    query {
      allWpFaqRo: allWpFaq(filter: { language: { code: { eq: RO } } }) {
        nodes {
          id
          faqFields {
            answer
            question
          }
        }
      }
      allWpFaqEn: allWpFaq(filter: { language: { code: { eq: EN } } }) {
        nodes {
          id
          faqFields {
            answer
            question
          }
        }
      }
    }
  `)

  const faqs = {
    en: allWpFaqEn,
    ro: allWpFaqRo,
  }

  return faqs[language].nodes
}
