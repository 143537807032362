import React from "react"
import PropTypes from "prop-types"
import "./EnsButton.scss"

const EnsButton = ({
  variant,
  type,
  children,
  onClick,
  disabled,
  className,
}) => {
  const btnClasses = `ens-button ${variant} ${className} ${
    children ? "-with-children" : "-single"
  } `

  return (
    <button
      disabled={disabled}
      type={type === "submit" ? "submit" : "button"}
      onClick={onClick}
      className={btnClasses}
    >
      {children}
    </button>
  )
}

EnsButton.defaultProps = {
  type: "button",
  disabled: false,
  onClick: () => {},
  className: "",
}

EnsButton.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary", "ghost-link"]).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

export default EnsButton
